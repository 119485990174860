<template>
  <div class="our-work">
    <div class="page-body-single" id="start">
        <div class="page-body-inner">
          <section style="text-align: center">
            <h1>Our Work</h1>
            <div class="portfolio-list">
              <div
                v-for="(portfolio, index) in portfolioData"
                :key="index"
                :class="index === 0 ? 'first-post' : 'post'"
              >
                <portfoliocard :postId="portfolio.id" />
              </div>
            </div>
          </section>
        </div>
    </div>
    <footerdiv />
  </div>
</template>
<script>
import footerdiv from "@/partials/footer.vue";
import portfoliocard from "@/partials/portfolioCard.vue";
export default {
data() {
    return {
      portfolioData: null,
    };
  },
  components: {
    portfoliocard,
    footerdiv
  },
  computed: {
    pageData() {
      var routePath = this.$route.fullPath;

      if (routePath === "/") {
        var permaLink = "/";
      } else {
        permaLink = this.$route.path + "/";
      }

      return this.$store.getters.getPage(permaLink);
    },
  },
  mounted() {
    this.getCards();
    this.bigNav(false);
  },
  methods: {
    getCards() {
      this.portfolioData = this.$store.getters.getPortfolioAll;
    },
    bigNav(value) {
      this.$store.commit("TOGGLE_NAVBAR_SIZE", value);
    },
  },
   metaInfo() {
    return {
      title: this.pageData.yoast_meta.yoast_wpseo_title,
      link: [
        {
          rel: "canonical",
          href: "https://russell-brown.co.uk" + this.$route.path
        }
      ],
      meta: [

            {
              vmid: "description",
              name: "description",
              content: this.pageData.yoast_meta.yoast_wpseo_metadesc
            },

            // Twitter Meta Tags
            {
              vmid: "twitter:card",
              name: "twitter:card",
              content: "summary_large_image"
            },

            {
              vmid: "twitter:title",
              name: "twitter:title",
              content: this.pageData.yoast_meta.yoast_wpseo_title
            },

            {
              vmid: "twitter:image",
              name: "twitter:image",
              content: this.pageData.fimg_url
            },

            {
              vmid: "twitter:description",
              name: "twitter:description",
              content: this.pageData.yoast_meta.yoast_wpseo_metadesc
            },
            //Open Graph Integrations
            {
              vmid: "og:image",
              property: "og:image",
              content: this.pageData.fimg_url
            },
            { vmid: "og:title", property: "og:title", content: this.pageData.yoast_meta.yoast_wpseo_title },
            { vmid: "og:locale", property: "og:locale", content: "en_GB" },
            { vmid: "og:type", property: "og:type", content: "article" },
            { vmid: "og:url", property: "og:url", content: this.pageData.link },
            {
              vmid: "og:site_name",
              property: "og:site_name",
              content: "Russell Brown Interiors"
            },
            {
              vmid: "og:description",
              property: "og:description",
              content: this.pageData.yoast_meta.yoast_wpseo_metadesc
            },
            {
              vmid: "article:modified_time",
              name: "article:modified_time",
              content: this.pageData.modified_gmt + "+00:00"
            },
            {
              vmid: "article:published_time",
              name: "article:published_time",
              content: this.pageData.date_gmt + "+00:00"
            },
            {
              vmid: "og:updated_time",
              name: "og:updated_time",
              content: this.pageData.modified_gmt + "+00:00"
            },

      ]
    };
  }
};
</script>